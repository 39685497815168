<template>
  <div class="academic-programs">
    <div class="header-section">
      <h1>Academic Programs at S.K. Education Group</h1>
      <p>
        At S.K. Education Group, we offer a wide range of academic programs designed to cater to various fields of study and professional interests. Our diverse curriculum includes courses in paramedical sciences, law, engineering, agriculture, computer science, marketing, sports, yoga, and safety. Each program is crafted to provide students with the knowledge and skills necessary to excel in their chosen fields.
      </p>
    </div>

    <div class="program-section">
      <h2>Paramedical Courses</h2>
      <ul>
        <li><strong>CMS-ED:</strong> Comprehensive training in medical and healthcare services, focusing on essential clinical and administrative skills.</li>
        <li><strong>DMLT:</strong> Diploma in Medical Laboratory Technology, providing expertise in diagnostic laboratory procedures.</li>
        <li><strong>BMLT:</strong> Bachelor’s degree in Medical Laboratory Technology, advancing skills in medical diagnostics and laboratory management.</li>
        <li><strong>BPH:</strong> Bachelor’s in Public Health, focusing on community health, epidemiology, and public health management.</li>
        <li><strong>MPH:</strong> Master’s in Public Health, offering advanced knowledge in health policy, management, and research.</li>
        <li><strong>BSc (MLT):</strong> Bachelor of Science in Medical Laboratory Technology, combining theoretical knowledge with practical lab skills.</li>
      </ul>
    </div>

    <div class="program-section">
      <h2>Law Courses</h2>
      <ul>
        <li><strong>B.A. L.L.B.:</strong> Integrated Bachelor of Arts and Bachelor of Laws, providing a comprehensive understanding of legal principles and practices.</li>
        <li><strong>L.L.B.:</strong> Bachelor of Laws, focusing on core areas of legal studies and preparation for legal practice.</li>
        <li><strong>L.L.M.:</strong> Master of Laws, offering specialization in various branches of law for advanced legal knowledge and research.</li>
      </ul>
    </div>

    <div class="program-section">
      <h2>Engineering Courses</h2>
      <ul>
        <li><strong>Polytechnic (All Trade):</strong> Diploma programs across various engineering trades, providing practical skills and industry knowledge.</li>
        <li><strong>B.Tech. (All Trade):</strong> Bachelor of Technology in various engineering disciplines, emphasizing both theoretical and practical aspects.</li>
        <li><strong>M.Tech:</strong> Master of Technology, offering advanced study in specialized engineering fields and research opportunities.</li>
      </ul>
    </div>

    <div class="program-section">
      <h2>Agriculture Courses</h2>
      <ul>
        <li><strong>B.Sc (Ag):</strong> Bachelor of Science in Agriculture, focusing on crop production, soil science, and agricultural practices.</li>
        <li><strong>M.Sc (Ag):</strong> Master of Science in Agriculture, providing advanced knowledge in agricultural research and technology.</li>
      </ul>
    </div>

    <div class="program-section">
      <h2>Computer Courses</h2>
      <ul>
        <li><strong>D.C.A:</strong> Diploma in Computer Applications, covering fundamental computer skills and applications.</li>
        <li><strong>B.C.A:</strong> Bachelor of Computer Applications, focusing on software development, programming, and IT management.</li>
        <li><strong>M.C.A:</strong> Master of Computer Applications, offering advanced study in computer science and application development.</li>
      </ul>
    </div>

    <div class="program-section">
      <h2>Marketing Courses</h2>
      <ul>
        <li><strong>B.B.A.:</strong> Bachelor of Business Administration with a focus on marketing principles, strategies, and business management.</li>
        <li><strong>M.B.A.:</strong> Master of Business Administration with specialization in marketing, providing advanced knowledge in market analysis and strategic planning.</li>
      </ul>
    </div>

    <div class="program-section">
      <h2>Sports Courses</h2>
      <ul>
        <li><strong>D.P.Ed:</strong> Diploma in Physical Education, focusing on physical fitness, sports coaching, and athletic training.</li>
        <li><strong>B.P.Ed:</strong> Bachelor of Physical Education, providing a comprehensive understanding of sports science and physical education methodologies.</li>
      </ul>
    </div>

    <div class="program-section">
      <h2>Yoga Courses</h2>
      <ul>
        <li><strong>B.A. & B.Sc. (Yoga):</strong> Bachelor’s degrees in Yoga, combining theoretical knowledge with practical training in yoga practices and philosophy.</li>
      </ul>
    </div>

    <div class="program-section">
      <h2>Safety Courses</h2>
      <ul>
        <li><strong>Diploma in Fire Safety:</strong> Specialized training in fire safety procedures, emergency response, and hazard management.</li>
        <li><strong>B.Sc (Fire Safety):</strong> Bachelor of Science in Fire Safety, focusing on advanced fire prevention, safety management, and risk assessment.</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    // Data related to courses can be added here if needed
  }),
}
</script>

<style scoped>
/* General Styles */
.academic-programs {
  font-family: 'Arial', sans-serif;
  color: #333;
  padding:20px 130px;
  background-color: #f9f9f9;
}

/* Header Section */
.header-section {
  text-align: center;
  margin-bottom: 30px;
  padding: 20px;
  background-color: #2c3e50;
  color: white;
  border-radius: 10px;
}

.header-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
  border-radius: 10px;
}

.header-section h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
}

.header-section p {
  font-size: 1.2em;
  margin-bottom: 0;
}

/* Program Section */
.program-section {
  margin-bottom: 30px;
}

.program-section h2 {
  color: #2980b9;
  margin-bottom: 15px;
  font-size: 1.8em;
}

.program-section ul {
  list-style: none;
  padding: 0;
}

.program-section ul li {
  /* background: url('/path/to/icon.png') no-repeat left center; */
  background-size: 20px 20px;
  padding-left: 40px;
  margin-bottom: 10px;
  font-size: 1.1em;
  color: #34495e;
}

/* Specific Colors for Categories */
.program-section:nth-child(odd) h2 {
  color: #16a085;
}

.program-section:nth-child(even) h2 {
  color: #c0392b;
}

/* Hover Effects */
.program-section ul li:hover {
  color: #2c3e50;
  background-color: #ecf0f1;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}


@media screen and (max-width:500px){
  .academic-programs {
    font-family: 'Arial', sans-serif;
    color: #333;
    padding:5px 5px;
    background-color: #f9f9f9;
    width: 100%;
  }
}
</style>