import { render, staticRenderFns } from "./NotificationComponent.vue?vue&type=template&id=12de7815&scoped=true"
import script from "./NotificationComponent.vue?vue&type=script&lang=js"
export * from "./NotificationComponent.vue?vue&type=script&lang=js"
import style0 from "./NotificationComponent.vue?vue&type=style&index=0&id=12de7815&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12de7815",
  null
  
)

export default component.exports