<template>
  <div>
    <NavbarComponent @tab-selected="handleTabChange" />
    <BodyComponent :currentTab="currentTab" />
    <FooterComponent />
  </div>
</template>

<script>
import NavbarComponent from '../components/NavbarComponent.vue';
import BodyComponent from '../components/BodyComponent.vue';
import FooterComponent from '../components/FooterComponent.vue';

export default {
  name: 'Home',
  components: {
    NavbarComponent,
    BodyComponent,
    FooterComponent,
  },
  data() {
    return {
      currentTab: 'home' // Default tab to be displayed
    };
  },
  methods: {
    handleTabChange(selectedTab) {
      console.log('selectedTab', selectedTab)
      this.currentTab = selectedTab;
    }
  }
}
</script>
