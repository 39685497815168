<template>
  <div>
    <!-- Carousel Section -->
    <v-carousel
      height="625"
      show-arrows="hover"
      cycle
      hide-delimiter-background
    >
      <v-carousel-item
        v-for="(slide, i) in slides"
        :key="i"
      >
        <v-img
          :src="slide"
          height="855"
          width="100%"
          class="fill-height"
          cover
        ></v-img>
      </v-carousel-item>
    </v-carousel>

    <div>
      <div class="bulletin-container">
        <div class="bulletin-label">Bulletin</div>
        <div class="bulletin-text">
           <marquee behavior="" direction="left"> The last date for admission in 9th and 10th grades and for submitting the exam forms for 10th and 12th grades is 30/08/2024.</marquee>
        </div>
    </div>
    </div>

    <!-- Main Board Section -->
    <div class="board_section">
      <!-- Tabs Section -->
      <div class="board_section_1">
        <ul class="tabs">
          <li
            v-for="(tab, index) in tabs"
            :key="index"
            @click="selectTab(index)"
            :class="{ active: selectedTab === index }"
          >
            {{ tab }}
          </li>
        </ul>
      </div>
      
      <!-- Content Display Section -->
      <div class="board_section_2">
        <div v-if="selectedTab !== null" class="tab-content">
          <p v-html="tabContents[selectedTab]"></p>
        </div>
      </div>
      
      <div class="board_section_3">
        <h2 style="width:100%; background-color:#ECD8A2; padding:10px; color:white;">Notifications</h2>
        <ul>
          <li>DDU - The last date for admission to the 3rd and 5th semesters of the B.A. program is August 25, 2024, </li>
          <li>UPRTOT - The last date for admission to the 3rd and 5th semesters is August 30, 2024.</li>
          <li>The last date for admission in 9th and 10th grades and for submitting the exam forms for 10th and 12th grades is 30/08/2024.</li>
        </ul>
      </div>
    </div>

    <!-- Principal's Desk Section -->
    <div class="principal-desk-container">
      <div class="principal-desk">
        <div class="principal-content">
          <img src="@/assets/md.png"  style="width:100%; height:380px;" alt="Dr. Abdul haque" class="principal-image" />
          <div class="text-content">
            <h3>Dr. Abdul haque</h3>
            <p class="designation">Chief Managing Director</p>
          </div>
        </div>
        <div class="desk-message">
          <h2>Chief Managing Directo’s Desk</h2>
          <p>
            On behalf of the entire S.K. EDUCATION GROUP, I am thrilled to welcome you to our institution. 
            Our dedication lies in providing students with innovative, comprehensive, and advanced learning opportunities. 
            We focus on nurturing their moral values and equipping them with the skills and qualities necessary 
            to become inventive problem solvers and analytical thinkers. We prepare our students to face the challenges 
            of a highly competitive world and guide them towards successful career progression, while also shaping them 
            into responsible citizens capable of meeting the needs of a developing nation.
          </p>
          <p>
              Our College boasts a highly skilled, dedicated, and talented faculty who help students stay ahead 
              of academic challenges and advancements. Our teaching methodologies promote inter-disciplinary approaches 
              through innovative projects, conferences, seminars, lectures, and workshops. Experiential learning techniques 
              are employed to effectively implement the curriculum.
          </p>
        </div>
      </div>
    </div>

    <!-- Our Facilities Section -->
    <div class="ourFacilities">
      <h1 style="margin-bottom:30px;">Our Facilities</h1>
      <div class="svg_image_section">
        <img src="https://cdn-icons-png.flaticon.com/512/2795/2795550.png" alt="facility icon" />
        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/02/Circle-icons-computer.svg/1024px-Circle-icons-computer.svg.png" alt="facility icon" />
        <img src="https://cdn-icons-png.freepik.com/512/14669/14669983.png" alt="facility icon" />
        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSMo_0zljhv623mPXZsmYb5HwKw6Ba2-h6CAg&s" alt="facility icon" />
        <img src="https://img.freepik.com/premium-vector/agriculture-icon-logo-vector-design-template_827767-2376.jpg" alt="facility icon" />
        <img src="https://www.svgrepo.com/show/181747/library-book.svg" alt="facility icon" />
      </div>
    </div>

    <div style="background-color:gray;">
      <div class="stats-section">
        <div class="stat-item" v-for="(stat, index) in stats" :key="index">
          <div class="stat-number" style="color:white;">
            {{ displayNumber(stat.number, stat.max) }}+
          </div>
          <div class="stat-label" style="color:white;">
            {{ stat.label }}
          </div>
        </div>
      </div>
    </div>

    <!-- Affiliated With Section -->
    <div class="ourFacilities">
      <h1  style="margin-bottom:30px;">Affiliated With</h1>
      <div class="svg_image_section">
        <div class="names">
          <a href="https://upmsp.edu.in/" target="_blank"><img src="https://iconape.com/wp-content/png_logo_vector/board-of-high-school-intermediate-uttar-pradesh-logo.png" alt="affiliation logo" /></a>
          <p>UP Board</p> 
        </div>
        <div class="names">
          <a href="https://www.bosse.ac.in/" target="_blank"><img src="https://www.bosse.org.in/Images/logoBOSSE.png" alt="affiliation logo" /></a>
          <p>Board Of Open Schooling <br />& Skill Education</p>
        </div>
        <div class="names">
          <a href="https://madarsaboard.upsdc.gov.in/About.aspx" target="_blank"><img src="https://madarsaboard.upsdc.gov.in/homeassets/images/HomeImg/Seal_of_Uttar_Pradesh.png" alt="affiliation logo" /></a> 
          <p>Uttar Pradesh Board of Madarsh Education</p></div> 
        <div class="names">
          <a href="http://www.uprtou.ac.in/" target="_blank"><img src="https://uprtou.co.in/public/images/logo.png" alt="affiliation logo" /></a> 
          <p>Uttar Pradesh Rajarshi Tandon Open University, Prayagraj</p></div>
        <div class="names">
          <a href="https://ddugu.ac.in/newweb/index.php" target="_blank"><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTN5QRw5S_stJPGA8crAv5QhPNq0Aem32Isk_6DStPYOpWwpqRWhXN1MJ_ZfWe-XQxqObw&usqp=CAU" alt="affiliation logo" /> </a>
          <p>Deen Dayal Upadhyaya<br /> Gorakhpur University</p></div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      stats: [
        { number: 0, max: 9000, label: "ALUMNI" },
        { number: 0, max: 100, label: "FACULTY" },
        { number: 0, max: 25, label: "TRANSPORTATION" },
        { number: 0, max: 700, label: "STUDENTS" },
      ],
      slides: [
        require('../assets/sldr1.jpg'),
        require('../assets/sldr2.jpg'),
        require('../assets/sldr3.jpg'),
        require('../assets/sldr4.jpg'),
        require('../assets/sldr5.jpg'),
        require('../assets/sldr6.jpg'),
      ],
      tabs: [
        'Student Facilities',
        'Transportations',
        'Playing Ground',
        'Affiliated By'
      ],
      selectedTab: 0,
      tabContents: [
        `*Student Facilities:*
        Our institution offers state-of-the-art facilities for students, including well-equipped laboratories, 
        modern libraries with extensive collections of books and digital resources, 
        recreational areas for relaxation and socialization, and health centers providing medical support. 
        Students have access to high-speed internet, computer labs, and study lounges that enhance their learning experience. 
        The campus also features dining halls with diverse food options catering to various dietary preferences.`,
        
        `*Transportations:*
        We provide a robust transportation system that ensures safe and reliable transit for students and staff. 
        The institution operates a fleet of buses covering various routes within the city and surrounding areas. 
        Each bus is equipped with GPS tracking and follows strict safety protocols. 
        Additionally, there are dedicated shuttles for inter-campus travel and special events. 
        Students can also access carpool services and bicycle-sharing programs for eco-friendly commuting.`,
        
        `*Playing Ground:*
        Our campus boasts expansive playing grounds that support a wide range of sports and physical activities. 
        Facilities include football and cricket fields, basketball and tennis courts, a swimming pool, 
        and an indoor gymnasium with modern equipment. 
        We encourage students to participate in intramural sports, intercollegiate competitions, and fitness programs 
        to promote a healthy and active lifestyle.`,
        
        `*Affiliated By:*
        We are affiliated with several prestigious national and international organizations. 
        Our programs are accredited by top accrediting bodies, ensuring that our education meets global standards. 
        We have partnerships with leading universities UP BOARD, BOSSE, Deen Dayal Upadhyaya Gorakhpur University,
        Uttar Pradesh Rajarshi Tandon Open University Prayagraj, Uttar Pradesh Board of Madarsh Education, facilitating student exchanges, collaborative research, 
        . Our affiliations strengthen our curriculum, providing students with a competitive edge in their careers.`
      ]
    };
    
  },
  methods: {
    selectTab(index) {
      this.selectedTab = index;
    },
    incrementNumbers() {
    this.stats.forEach((stat) => {
      const incrementAmount = Math.ceil(stat.max / 80); // Number of increments over 4 seconds (50ms interval)

      const interval = setInterval(() => {
        if (stat.number < stat.max) {
          stat.number += incrementAmount;
          if (stat.number > stat.max) {
            stat.number = stat.max; // Prevent overshooting
          }
        } else {
          clearInterval(interval);
        }
      }, 50); // Interval time in milliseconds
    });
  },
  displayNumber(current, max) {
    return current >= max ? max : current;
  },
  },

  mounted() {
    this.incrementNumbers();
  },
};
</script>

<style scoped>
.board_section {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
}

.board_section_1,
.board_section_2,
.board_section_3 {
  padding: 10px;
  border: 1px solid #ECD8A2;
}

.board_section_1 {
  width: 33%;
  background-color: #f5f5f5;
  border-right: 2px solid #ddd;
}

.board_section_2 {
  width: 33%;
  background-color: #fff;
}

.board_section_3 {
  width: 33%;
  background-color: #fff;
}

.tabs {
  list-style-type: none;
  padding: 0;
}

.tabs li {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.tabs li.active {
  background-color: #ECD8A2;
  color: white;
}

.tabs li:hover {
  background-color: #ECD8A2;
  color: white;
}

.tab-content {
  padding: 15px;
  font-size: 16px;
}

.principal-desk-container {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  width: 100%;
  align-items: center;
}

.principal-desk {
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  width: 70%;
}

.principal-content{
  width: 30%;
}

.principal-image {
  border-radius: 50%;
  margin-right: 20px;
}

.text-content {
  margin-right: 20px;
}

.text-content {
  font-size: 22px;
  margin-bottom: 5px;
  width: 100%;
  text-align: center;
}

.designation {
  color: #777;
}
.desk-message{
  width: 60%;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  align-items: center;
  justify-content: center;
}
.desk-message h2 {
  margin-bottom: 10px;
  width: 75%;
}

.read-more {
  margin-top: 15px;
  padding: 10px 20px;
  background-color: #ECD8A2;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.read-more:hover {
  background-color: #0056b3;
}

.ourFacilities {
  text-align: center;
  margin: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  
}

.svg_image_section {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  width: 80%;
}

.svg_image_section img {
  width: 120px;
  height: 120px;
  transition: transform 0.3s;
}

.svg_image_section img:hover {
  transform: scale(1.1);
}

.stats-section {
  display: flex;
  justify-content: space-around;
  padding: 40px 0;
}

.stat-item {
  text-align: center;
  width: 20%;
}

.stat-number {
  font-size: 2rem;
  font-weight: bold;
  color: #3f51b5;
}

.stat-label {
  font-size: 1rem;
  color: #333;
}

.names{
  width: 20%;
  height: 200px;
  margin: 10px;
  border-radius:8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 13px 11px -2px
}


.bulletin-container {
    display: flex;
    align-items: center;
    background-color: #2f4a50;
    color: white;
    padding: 10px;
}
.bulletin-label {
    background-color: #b23230;
    padding: 10px 20px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.bulletin-text {
    padding-left: 20px;
    white-space: nowrap;
}

@media screen and (max-width: 900px) {
    .board_section {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin: 20px 0;
    }
    .board_section_1, .board_section_2, .board_section_3{
      width: 100%;
    }
    .ourFacilities {
      text-align: center;
      margin: 40px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .svg_image_section {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      margin-top: 20px;
      width: 80%;
      height: auto;
    }

    .stats-section {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      padding: 40px 0;
    }

    .stat-item {
      text-align: center;
      width: 50%;
      margin: 5px auto;
    }

    .principal-desk {
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      padding: 20px;
      background-color: #f5f5f5;
      border: 1px solid #ddd;
      width: 95%;
    }

    .principal-content{
      width: 50%;
    }

    .desk-message{
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-left: 5px;
      align-items: center;
      justify-content: center;
    }
    .desk-message h2 {
      margin-bottom: 10px;
      width: 100%;
    }
    
}

@media screen and (max-width: 500px) {
    .names{
    width: 90%;
    height: 200px;
    margin: 10px;
    border-radius:8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 13px 11px -2px
  }
    .board_section {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin: 20px 0;
    }
    .board_section_1, .board_section_2, .board_section_3{
      width: 100%;
    }
    .ourFacilities {
      text-align: center;
      margin: 40px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .svg_image_section {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      margin-top: 20px;
      width: 80%;
      height: auto;
    }

    .stats-section {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      padding: 40px 0;
    }

    .stat-item {
      text-align: center;
      width: 50%;
      margin: 5px auto;
    }

    .principal-desk {
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      padding: 20px;
      background-color: #f5f5f5;
      border: 1px solid #ddd;
      width: 95%;
    }

    .principal-content{
      width: 100%;
    }

    .desk-message{
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-left: 5px;
      align-items: center;
      justify-content: center;
    }
    .desk-message h2 {
      margin-bottom: 10px;
      width: 100%;
    }
    
}
</style>
