<template>
  <div>
    <component :is="componentMap[currentTab]" />
  </div>
</template>

<script>
import HomePageComponent from './HomePageComponent.vue';
import AboutComponent from './AboutComponent.vue';
import AcadamicComponent from './AcadamicComponent.vue';
import ContactComponent from './ContactUsComponent.vue';
import ResultComponent from './ResultTabComponent.vue';
import NotificationComponent from './NotificationComponent.vue';
import GalleryComponent from './GalleryComponent.vue';
import CollegePage from './GalleryComponent.vue';
// import CoursesComponent from './CourcesDetailComponent.vue';

export default {
  name: 'BodyComponent',
  props: {
    currentTab: {
      type: Number,
      required: true
    }
  },
  components: {
    HomePageComponent,
    AboutComponent,
    AcadamicComponent,
    ContactComponent,
    ResultComponent,
    NotificationComponent,
    GalleryComponent,
    CollegePage
    // CoursesComponent
  },
  computed: {
    componentMap() {
      return [
        'HomePageComponent',
        'AboutComponent',
        'AcadamicComponent',
        // 'CoursesComponent',
        'ResultComponent',
        'GalleryComponent',
        'NotificationComponent',
        'ContactComponent',
        'CollegePage'
      ];
    }
  }
}
</script>
