<template>
    <div>
        <div>
            <div class="top_image">
              <div class="background">
                <h1>Contact Us</h1>
              </div>
            </div>

            <div class="contact_details_section">
              <div class="contact_details_section_box">
                <div class="phone_email_section" style="background-color:#66ccff;">
                  <img style="width:90px; height:90px;" src="https://cdn.pixabay.com/photo/2021/10/29/21/40/phone-icon-6753051_1280.png" />
                  <h1>+91 73948 84322</h1>
                </div>
                <div class="phone_email_section" style="background-color:#66ccff;">
                  <img style="width:90px; height:90px;" src="https://cdn.pixabay.com/photo/2021/10/29/21/40/phone-icon-6753051_1280.png" />
                  <h1>+91 73938 86146</h1>
                </div>
                <div class="phone_email_section" style="background-color:#66ccff;">
                  <img style="width:90px; height:90px;" src="https://cdn.pixabay.com/photo/2016/06/13/17/30/mail-1454731_640.png" />
                  <h1>info@skedugroup.in</h1>
                </div>
                <div class="phone_email_section" style="background-color:#66ccff;">
                  <img style="width:90px; height:90px;" src="https://cdn.pixabay.com/photo/2016/06/13/17/30/mail-1454731_640.png" />
                  <h1>contact@skedugroup.in</h1>
                </div>
              </div>
              <div class="contact_details_section_box">
                <div class="contact-form">
                  <v-form ref="form" v-model="valid">
                    <v-text-field
                    style="width:600px;"
                      v-model="form.name"
                      label="Name"
                      :rules="[v => !!v || 'Name is required']"
                      required
                      outlined
                    ></v-text-field>

                    <v-select
                      v-model="form.course"
                      :items="courses"
                      label="Course"
                      :rules="[v => !!v || 'Please select a course']"
                      required
                      outlined
                    ></v-select>

                    <v-text-field
                      v-model="form.email"
                      label="Email"
                      :rules="[v => !!v || 'Email is required', v => /.+@.+\..+/.test(v) || 'E-mail must be valid']"
                      required
                      outlined
                    ></v-text-field>

                    <v-text-field
                      v-model="form.contactNumber"
                      label="Contact Number"
                      :rules="[v => !!v || 'Contact number is required', v => /^\d+$/.test(v) || 'Contact number must be numeric']"
                      required
                      outlined
                    ></v-text-field>

                    <v-textarea
                      v-model="form.message"
                      label="Message"
                      :rules="[v => !!v || 'Message is required']"
                      required
                      outlined
                    ></v-textarea>

                    <v-btn @click="submitForm" :disabled="!valid" color="primary">Submit</v-btn>
                  </v-form>
                </div>
              </div>
            </div>

            <div class="map_emb">
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7155.954226011755!2d83.9086348!3d26.2624062!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399235d48c92d0b1%3A0x2797eefecde2a434!2sS%20K%20Inter%20College%20%26%20S%20K%20Kanya%20Mahavidyalaya!5e0!3m2!1sen!2sin!4v1725380904369!5m2!1sen!2sin" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>            
        </div>
    </div>
</template>

<script>
  export default {
    data() {
      return {
        valid: false,
        form: {
          name: '',
          course: '',
          email: '',
          contactNumber: '',
          message: ''
        },
        courses: ['Engineering', 'Medical', 'Arts', 'Science', 'Commerce']
      };
    },
    methods: {
      submitForm() {
        if (this.$refs.form.validate()) {
          // Handle form submission, e.g., send data to the server
          console.log("Form submitted:", this.form);
          // Reset the form after submission
          this.$refs.form.reset();
        }
      }
    }
  };
</script>

<style>
  .top_image{
    background-size:cover ;
    background-image: url('../assets/contact_college_image.jpg');
    background-position:center;
    background-attachment:fixed;
    height: 200px;
  }

  .background{
    background: rgba(0, 0, 0, 0.637);
    height: 200px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    color:white;
    font-size: 2rem;
  }

  .map_emb{
    width: 100%;
    height: 600px;
    border-radius: 120px;
  }

  .contact_details_section{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .contact_details_section_box{
    width: 50%;
    height: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contact-form {
    max-width: 600px;
    margin: 70px auto;
  }

  .phone_email_section{
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 150px;
    border-radius:10px;
    justify-content: space-between;
    align-items: center;
    margin:10px;
  }

  .phone_email_section p {
    font-size: 2.5rem;
  }

  @media screen and (max-width:900px) {
    .contact_details_section{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .contact_details_section_box{
      width: 100%;
      display: flex;
      flex-direction: column;
      height: auto;
      align-items: center;
    }

    .phone_email_section{
      display: flex;
      flex-direction: column;
      width: 90%;
      height: 150px;
      border-radius:10px;
      justify-content: space-between;
      align-items: center;
      margin:10px;
    }

    .contact-form{
      width: 95%;
      margin: 10px auto;
      padding: 20px;
    }
  }


</style>