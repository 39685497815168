<template>
  <div>
    <div class="results-section">
      <h1>Results</h1>
      <div class="results-content">
        <div class="result-card">
          <h2>10th Grade Results</h2>
          <p>Here you can view the results for the 10th grade examinations. Details include subject-wise scores, overall performance, and rank if applicable. Students can check their grades, pass percentages, and other relevant information.</p>
          <a hrf="https://results.upmsp.edu.in/" target="_blank">LINK</a>
        </div>
        <div class="result-card">
          <h2>12th Grade Results</h2>
          <p>This section provides the results for the 12th grade examinations. Students can access their marks, subject-wise performance, and overall grades. Information regarding merit lists and rank achievements will also be available here.</p>
          <a hrf="https://results.upmsp.edu.in/" target="_blank">LINK</a>
        </div>
        <div class="result-card">
          <h2>DDU Results</h2>
          <p>Results for various programs and courses under DDU (Deen Dayal Upadhyaya University) are displayed here. This includes performance summaries, subject scores, and overall grades for different academic sessions.</p>
          <a hrf="https://erp.ddugu.ac.in/All_Result.aspx" target="_blank">LINK</a>
        </div>
        <div class="result-card">
          <h2>Board Of Open Schooling & Skill Education Results</h2>
          <p>Find the results for exams conducted by the Board of Open Schooling & Skill Education. This section includes details on subject marks, skill certification results, and overall performance summaries.</p>
          <a hrf="https://www.bosse.ac.in/student/student_login.php" target="_blank">LINK</a>
        </div>
        <div class="result-card">
          <h2>राजर्षि पुरुषोत्तम दास टण्डन Results</h2>
          <p>This section provides results for programs and courses under Rajarshi Purushottam Das Tandon University. Students can access their exam scores, grade sheets, and overall academic performance here.</p>
          <a hrf="http://www.uprtou.ac.in/result_front_page.php" target="_blank">LINK</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ResultsComponent',
};
</script>

<style scoped>
.results-section {
  max-width: 90%;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h1 {
  color: #1E90FF;
  text-align: center;
  margin-bottom: 20px;
}

.results-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.result-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h2 {
  color: #FF6347;
  margin-bottom: 10px;
}

p {
  color: #333;
  font-size: 16px;
  line-height: 1.6;
}
</style>
