import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_vm._m(0),_c('div',{staticClass:"contact_details_section"},[_vm._m(1),_c('div',{staticClass:"contact_details_section_box"},[_c('div',{staticClass:"contact-form"},[_c(VForm,{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VTextField,{staticStyle:{"width":"600px"},attrs:{"label":"Name","rules":[v => !!v || 'Name is required'],"required":"","outlined":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c(VSelect,{attrs:{"items":_vm.courses,"label":"Course","rules":[v => !!v || 'Please select a course'],"required":"","outlined":""},model:{value:(_vm.form.course),callback:function ($$v) {_vm.$set(_vm.form, "course", $$v)},expression:"form.course"}}),_c(VTextField,{attrs:{"label":"Email","rules":[v => !!v || 'Email is required', v => /.+@.+\..+/.test(v) || 'E-mail must be valid'],"required":"","outlined":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c(VTextField,{attrs:{"label":"Contact Number","rules":[v => !!v || 'Contact number is required', v => /^\d+$/.test(v) || 'Contact number must be numeric'],"required":"","outlined":""},model:{value:(_vm.form.contactNumber),callback:function ($$v) {_vm.$set(_vm.form, "contactNumber", $$v)},expression:"form.contactNumber"}}),_c(VTextarea,{attrs:{"label":"Message","rules":[v => !!v || 'Message is required'],"required":"","outlined":""},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}}),_c(VBtn,{attrs:{"disabled":!_vm.valid,"color":"primary"},on:{"click":_vm.submitForm}},[_vm._v("Submit")])],1)],1)])]),_vm._m(2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top_image"},[_c('div',{staticClass:"background"},[_c('h1',[_vm._v("Contact Us")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contact_details_section_box"},[_c('div',{staticClass:"phone_email_section",staticStyle:{"background-color":"#66ccff"}},[_c('img',{staticStyle:{"width":"90px","height":"90px"},attrs:{"src":"https://cdn.pixabay.com/photo/2021/10/29/21/40/phone-icon-6753051_1280.png"}}),_c('h1',[_vm._v("+91 73948 84322")])]),_c('div',{staticClass:"phone_email_section",staticStyle:{"background-color":"#66ccff"}},[_c('img',{staticStyle:{"width":"90px","height":"90px"},attrs:{"src":"https://cdn.pixabay.com/photo/2021/10/29/21/40/phone-icon-6753051_1280.png"}}),_c('h1',[_vm._v("+91 73938 86146")])]),_c('div',{staticClass:"phone_email_section",staticStyle:{"background-color":"#66ccff"}},[_c('img',{staticStyle:{"width":"90px","height":"90px"},attrs:{"src":"https://cdn.pixabay.com/photo/2016/06/13/17/30/mail-1454731_640.png"}}),_c('h1',[_vm._v("info@skedugroup.in")])]),_c('div',{staticClass:"phone_email_section",staticStyle:{"background-color":"#66ccff"}},[_c('img',{staticStyle:{"width":"90px","height":"90px"},attrs:{"src":"https://cdn.pixabay.com/photo/2016/06/13/17/30/mail-1454731_640.png"}}),_c('h1',[_vm._v("contact@skedugroup.in")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"map_emb"},[_c('iframe',{staticStyle:{"border":"0"},attrs:{"src":"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7155.954226011755!2d83.9086348!3d26.2624062!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399235d48c92d0b1%3A0x2797eefecde2a434!2sS%20K%20Inter%20College%20%26%20S%20K%20Kanya%20Mahavidyalaya!5e0!3m2!1sen!2sin!4v1725380904369!5m2!1sen!2sin","width":"100%","height":"100%","allowfullscreen":"","loading":"lazy","referrerpolicy":"no-referrer-when-downgrade"}})])
}]

export { render, staticRenderFns }