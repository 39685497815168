<template>
  <MainView />
</template>

<script>
  import MainView from '../components/MainIndexComponent.vue'

  export default {
    name: 'Home',

    components: {
      MainView,
    },
  }
</script>🎬 
