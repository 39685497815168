<template>
    <div>
        <div class="top_nav_bar">
            <div class="top_nav_content">

            </div>
            <div class="navbar_navSection">
                <div class="navbar_navSection_content">
                    <div class="navbar_navSection_content_1" style="">
                        <h1>ANJUMAN ISLAMIA <br/> ADARA TRUST</h1>
                        <p>Reg - iv -38 /  2012</p>
                        <p>Tilauli, Sohnag, Salempur, Dist - Deoria,<br /> state- Uttar Pradesh , PIN code 274509</p>
                    </div>
                    <!-- <img src="../assets/logo_dummy.jpg"  style="width:200px; height:200px" /> -->
                    
                </div>
                <div class="navbar_navSection_content">
                    <div class="navbar_navSection_content_2" style="">
                        <h1>S.K. EDUCATOIN GROUP</h1>
                        <p>S.K. INTER COLLEGE</p>
                        <p class="plain-text">
                            <router-link to="/skkanyamahavidyalaya">S.K. KANYA MAHAVIDYALAYA</router-link>
                        </p>
                    </div>
                    <img src="../assets/skLogo.png"  style="width:180px; height:200px" />
                </div>
            </div>
        </div>
        <div style="tabBar">
            <div class="tabs">
                <v-tabs
                    v-model="tab"
                    @change="tabChanged"
                    dark color="primary"
                >
                    <v-tab value="home">Home</v-tab>
                    <v-tab value="about">About</v-tab>
                    <v-tab value="acadamic">Acadamic</v-tab>
                    <v-tab value="result">Results</v-tab>
                    <v-tab value="gallery">Gallery</v-tab>
                    <v-tab value="notification">Notifications</v-tab>
                    <v-tab value="contactus">Contact us</v-tab>
                </v-tabs>
            </div>
            <div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
</template>


<script>
  export default {
    data: () => ({
      tab: 'home',
    }),
    methods: {
        tabChanged() {
            console.log('this.tab', this.tab)
            this.$emit('tab-selected', this.tab);
        }
    }
  }
</script>

<style scoped>
    .top_nav_bar{
        display: flex;
        flex-direction: column;
        height: 230px;
        width: 100%;
    }

    .top_nav_content{
        width: 100%;
        height: 40px;
        background-color: #ECD8A2;
        color:white;
    }

    .tabBar{
       position: sticky;
        top: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .navbar_navSection{
        height: 200px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .navbar_navSection_content{
        width: 30%;
        height: auto;
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .navbar_navSection_content_1{
        text-align:left; padding-left:30px;
    }

    .navbar_navSection_content{
        text-align:left;
    }

    .tabs{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .plain-text {
        color: white;
        margin: 0;
        padding: 0;
        text-decoration: none;
    }
    a {
        color: white; /* Inherits the color from the parent */
        text-decoration: none;
    }

    @media screen and (max-width: 900px){
         .navbar_navSection{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        .top_nav_bar{
            display: flex;
            flex-direction: column;
            width: 100%;
        }
        .navbar_navSection_content{
            width: 50%;
            display:flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .navbar_navSection_content_1{
            text-align:center;
            padding-left:0px;
        }
        .navbar_navSection_content{
            text-align:center;
        }

        .navbar_navSection_content img{
            display: none;
        }

    }

    @media screen and (max-width: 500px){
         .navbar_navSection{
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .top_nav_bar{
            display: flex;
            flex-direction: column;
            height: 730px;
            width: 100%;
        }
        .navbar_navSection_content{
            width: 100%;
            display:flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .navbar_navSection_content_1{
            text-align:center;
            padding-left:0px;
        }
        .navbar_navSection_content{
            text-align:center;
        }

        .navbar_navSection_content img{
            display: block;
        }

    }
</style>