<template>
    <footer class="footer">
        <div class="footer-container">
            <div class="footer-left">
                <img src="../assets/skLogo.png" alt="S.K. Education Group Logo" />
                <div>
                    <h1>S.K. EDUCATION GROUP</h1>
                    <p>S.K. INTER COLLEGE</p>
                    <p class="plain-text">
                        <router-link to="/skkanyamahavidyalaya">S.K. KANYA MAHAVIDYALAYA</router-link>
                    </p>
                </div>
            </div>
            <div class="footer-right">
                <div class="footer-section">
                    <h3>Quick Links</h3>
                    <a href="/result" target="_blank" rel="noopener noreferrer">Results</a>
                </div>
                <div class="footer-section">
                    <h3>Co-Curricular</h3>
                    <!-- <a href="#" target="_blank" rel="noopener noreferrer">Sports</a> -->
                    <router-link to="/nss" @click.native="openInNewTab('/nss')">NSS</router-link>
                </div>
                <div class="footer-section">
                    <h3>Important Links</h3>
                    <router-link to="/students-rules" @click.native="openInNewTab('/students-rules')">Student's Rules</router-link>
                    <router-link to="/teacher-rules" @click.native="openInNewTab('/teacher-rules')">Teacher's Rules</router-link>
                    <router-link to="/visitor-rules" @click.native="openInNewTab('/visitor-rules')">Visitor's Rules</router-link>
                    <a href="/dynamiteFileApk/app-release.apk" download="app-release.apk">
                        Dynamite File
                    </a>
                </div>
            </div>
        </div>
        <div class="footer-bottom">
            <p>Copyright © S.K. Education Group.</p>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'FooterComponent',
    methods: {
        openInNewTab(url) {
            window.open(url, '_blank', 'noopener,noreferrer');
        }
    }
};
</script>

<style scoped>
.footer {
    background-color: #2f4a50;
    color: white;
    padding: 20px 10px;
}

.footer-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1200px;
    margin: 0 auto;
}

.footer-left,
.footer-right {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    width: 50%;
}

.footer-left {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    text-align: center;
}

.footer-left img {
    max-width: 100px;
    margin-bottom: 10px;
}

.footer-left div {
    margin-top: 10px;
}

.footer-left h1 {
    font-size: 20px;
    margin-bottom: 10px;
}

.footer-left p {
    margin: 5px 0;
    line-height: 1.5;
}

.footer-right {
    flex: 1;
    display: flex;
    justify-content: space-between;
}

.footer-section {
    flex: 1;
    margin: 0 10px;
}

.footer-section h3 {
    font-size: 16px;
    border-bottom: 1px solid white;
    padding-bottom: 5px;
    margin-bottom: 10px;
}

.footer-section a {
    color: white;
    text-decoration: none;
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
}

.footer-section a:hover {
    text-decoration: underline;
}

.plain-text {
  color: white;
  margin: 0;
  padding: 0;
  text-decoration: none;
}
a {
  color: inherit; /* Inherits the color from the parent */
  text-decoration: none;
}

.footer-bottom {
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
}

@media (max-width: 768px) {
    .footer-right {
        flex-direction: column;
        text-align: center;
    }

    .footer-section {
        margin-bottom: 20px;
    }

    .footer-container {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
    }
    .footer-left{
        display: flex;
        flex-direction: row;
        text-align: center;
    }

    .footer-left,
    .footer-right {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        width: 100%;
        text-align: center;
    }
}

@media (max-width: 480px) {
   .footer-container {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
    }

    .footer-right {
        width: 100%;
    }

    .footer-left{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
    }

    .footer-section h3 {
        font-size: 14px;
    }

    .footer-section a {
        font-size: 12px;
    }
}
</style>
