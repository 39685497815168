<template>
  <div class="gallery-container">
    <!-- Banner Section -->
    <div class="banner">
      <h1>Gallery</h1>
    </div>
    
    <!-- Gallery Sections -->
    <div v-for="(section, index) in gallerySections" :key="index" class="gallery-section">
      <h2>{{ section.title }}</h2>
      <div class="image-row">
        <div v-for="(image, imgIndex) in section.images.slice(0, 4)" :key="imgIndex" class="image-card">
          <img :src="image" @click="openPopup(section.images, imgIndex)" />
        </div>
      </div>
      <button v-if="section.images.length > 4" class="show-all-btn" @click="openPopup(section.images)">
        Show All Images
      </button>
    </div>

    <!-- Image Popup -->
    <div v-if="showPopup" class="popup-overlay">
      <div class="popup-content">
        <span class="close-btn" @click="closePopup">&times;</span>
        <div class="popup-image-wrapper">
          <img :src="currentImage" class="popup-image" />
          <div class="arrow left-arrow" @click="prevImage">&#10094;</div>
          <div class="arrow right-arrow" @click="nextImage">&#10095;</div>
        </div>
        <div class="thumbnail-row">
          <div
            v-for="(image, thumbIndex) in popupImages"
            :key="thumbIndex"
            class="thumbnail-card"
            :class="{ active: thumbIndex === currentIndex }"
            @click="setCurrentImage(thumbIndex)"
          >
            <img :src="image" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      gallerySections: [
        { title: 'Events', images: [require('../assets/event_1.jpg'), require('../assets/event_2.jpg'), require('../assets/event_3.jpg'), require('../assets/event_5.jpg'), require('../assets/event_6.jpg'),] },
        { title: 'Social Events', images: [require('../assets/social_1.jpg'), require('../assets/social_2.jpg'), require('../assets/social_3.jpg'), require('../assets/social_4.jpg'), require('../assets/social_5.jpg')] },
        { title: 'Occasion', images: [require('../assets/occasion_1.jpg'), require('../assets/occasion_2.jpg'), require('../assets/occasion_3.jpg'), require('../assets/occasion_4.jpg'), require('../assets/occasion_5.jpg'), require('../assets/occasion_6.jpg'),] },
        { title: 'General', images: [require('../assets/general_1.jpg'), require('../assets/general_2.jpg'), require('../assets/general_3.jpg'), require('../assets/general_4.jpg')] },
        { title: 'Achievement', images: [require('../assets/achievement_3.jpg'), require('../assets/achievement_4.jpg'), require('../assets/achievement_1.jpg'), require('../assets/achievement_2.jpg'), require('../assets/achievement_5.jpg')] }
      ],
      showPopup: false,
      popupImages: [],
      currentImage: '',
      currentIndex: 0
    };
  },
  methods: {
    openPopup(images, index = 0) {
      this.popupImages = images;
      this.currentIndex = index;
      this.currentImage = images[index];
      this.showPopup = true;
    },
    closePopup() {
      this.showPopup = false;
    },
    prevImage() {
      this.currentIndex = (this.currentIndex - 1 + this.popupImages.length) % this.popupImages.length;
      this.currentImage = this.popupImages[this.currentIndex];
    },
    nextImage() {
      this.currentIndex = (this.currentIndex + 1) % this.popupImages.length;
      this.currentImage = this.popupImages[this.currentIndex];
    },
    setCurrentImage(index) {
      this.currentIndex = index;
      this.currentImage = this.popupImages[index];
    }
  }
};
</script>

<style scoped>
.gallery-container {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Banner Styling */
.banner {
  width: 100%;
  background-color: #333;
  color: white;
  text-align: center;
  padding: 20px 0;
  margin-bottom: 30px;
  border-radius: 12px;
}

.banner h1 {
  margin: 0;
  font-size: 2.5em;
  font-weight: bold;
}

/* Gallery Section Styling */
.gallery-section {
  margin-bottom: 30px;
  width: 100%;
}

.image-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  
}

.image-card {
  width: 23%;
  height: 200px;
  margin-bottom: 10px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  margin: 10px;
}

.image-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.image-card:hover img {
  transform: scale(1.1);
}

.show-all-btn {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Popup Styling */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  position: relative;
  width: 80%;
  max-width: 900px;
  background: white;
  padding: 20px;
  border-radius: 10px;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 2em;
  color: red;
  cursor: pointer;
  z-index: 100;
}

.popup-image-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-image {
  max-width: 100%;
  max-height: 500px;
  border-radius: 10px;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2em;
  color: black;
  cursor: pointer;
  user-select: none;
}

.left-arrow {
  left: 10px;
}

.right-arrow {
  right: 10px;
}

.thumbnail-row {
  display: flex;
  overflow-x: auto;
  margin-top: 20px;
  padding-bottom: 10px;
}

.thumbnail-card {
  width: 100px;
  height: 60px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;
  border: 2px solid transparent;
}

.thumbnail-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.thumbnail-card.active {
  border: 2px solid #333;
}

/* Tablet View */
@media (max-width: 768px) {
  .image-card {
    width: 48%;
  }

  .popup-content {
    width: 90%;
  }
}

/* Mobile View */
@media (max-width: 480px) {
  .image-card {
    width: 97%;
  }

  .popup-content {
    width: 95%;
  }

  .popup-image {
    max-height: 300px;
  }

  .thumbnail-card {
    width: 80px;
    height: 50px;
  }
}
</style>
