import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VCarousel,{attrs:{"height":"625","show-arrows":"hover","cycle":"","hide-delimiter-background":""}},_vm._l((_vm.slides),function(slide,i){return _c(VCarouselItem,{key:i},[_c(VImg,{staticClass:"fill-height",attrs:{"src":slide,"height":"855","width":"100%","cover":""}})],1)}),1),_c('div',[_c('div',{staticClass:"bulletin-container"},[_c('div',{staticClass:"bulletin-label"},[_vm._v("Bulletin")]),_c('div',{staticClass:"bulletin-text"},[_c('marquee',{attrs:{"behavior":"","direction":"left"}},[_vm._v(" The last date for admission in 9th and 10th grades and for submitting the exam forms for 10th and 12th grades is 30/08/2024.")])],1)])]),_c('div',{staticClass:"board_section"},[_c('div',{staticClass:"board_section_1"},[_c('ul',{staticClass:"tabs"},_vm._l((_vm.tabs),function(tab,index){return _c('li',{key:index,class:{ active: _vm.selectedTab === index },on:{"click":function($event){return _vm.selectTab(index)}}},[_vm._v(" "+_vm._s(tab)+" ")])}),0)]),_c('div',{staticClass:"board_section_2"},[(_vm.selectedTab !== null)?_c('div',{staticClass:"tab-content"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.tabContents[_vm.selectedTab])}})]):_vm._e()]),_vm._m(0)]),_vm._m(1),_vm._m(2),_c('div',{staticStyle:{"background-color":"gray"}},[_c('div',{staticClass:"stats-section"},_vm._l((_vm.stats),function(stat,index){return _c('div',{key:index,staticClass:"stat-item"},[_c('div',{staticClass:"stat-number",staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.displayNumber(stat.number, stat.max))+"+ ")]),_c('div',{staticClass:"stat-label",staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s(stat.label)+" ")])])}),0)]),_vm._m(3)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"board_section_3"},[_c('h2',{staticStyle:{"width":"100%","background-color":"#ECD8A2","padding":"10px","color":"white"}},[_vm._v("Notifications")]),_c('ul',[_c('li',[_vm._v("DDU - The last date for admission to the 3rd and 5th semesters of the B.A. program is August 25, 2024, ")]),_c('li',[_vm._v("UPRTOT - The last date for admission to the 3rd and 5th semesters is August 30, 2024.")]),_c('li',[_vm._v("The last date for admission in 9th and 10th grades and for submitting the exam forms for 10th and 12th grades is 30/08/2024.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"principal-desk-container"},[_c('div',{staticClass:"principal-desk"},[_c('div',{staticClass:"principal-content"},[_c('img',{staticClass:"principal-image",staticStyle:{"width":"100%","height":"380px"},attrs:{"src":require("@/assets/md.png"),"alt":"Dr. Abdul haque"}}),_c('div',{staticClass:"text-content"},[_c('h3',[_vm._v("Dr. Abdul haque")]),_c('p',{staticClass:"designation"},[_vm._v("Chief Managing Director")])])]),_c('div',{staticClass:"desk-message"},[_c('h2',[_vm._v("Chief Managing Directo’s Desk")]),_c('p',[_vm._v(" On behalf of the entire S.K. EDUCATION GROUP, I am thrilled to welcome you to our institution. Our dedication lies in providing students with innovative, comprehensive, and advanced learning opportunities. We focus on nurturing their moral values and equipping them with the skills and qualities necessary to become inventive problem solvers and analytical thinkers. We prepare our students to face the challenges of a highly competitive world and guide them towards successful career progression, while also shaping them into responsible citizens capable of meeting the needs of a developing nation. ")]),_c('p',[_vm._v(" Our College boasts a highly skilled, dedicated, and talented faculty who help students stay ahead of academic challenges and advancements. Our teaching methodologies promote inter-disciplinary approaches through innovative projects, conferences, seminars, lectures, and workshops. Experiential learning techniques are employed to effectively implement the curriculum. ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ourFacilities"},[_c('h1',{staticStyle:{"margin-bottom":"30px"}},[_vm._v("Our Facilities")]),_c('div',{staticClass:"svg_image_section"},[_c('img',{attrs:{"src":"https://cdn-icons-png.flaticon.com/512/2795/2795550.png","alt":"facility icon"}}),_c('img',{attrs:{"src":"https://upload.wikimedia.org/wikipedia/commons/thumb/0/02/Circle-icons-computer.svg/1024px-Circle-icons-computer.svg.png","alt":"facility icon"}}),_c('img',{attrs:{"src":"https://cdn-icons-png.freepik.com/512/14669/14669983.png","alt":"facility icon"}}),_c('img',{attrs:{"src":"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSMo_0zljhv623mPXZsmYb5HwKw6Ba2-h6CAg&s","alt":"facility icon"}}),_c('img',{attrs:{"src":"https://img.freepik.com/premium-vector/agriculture-icon-logo-vector-design-template_827767-2376.jpg","alt":"facility icon"}}),_c('img',{attrs:{"src":"https://www.svgrepo.com/show/181747/library-book.svg","alt":"facility icon"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ourFacilities"},[_c('h1',{staticStyle:{"margin-bottom":"30px"}},[_vm._v("Affiliated With")]),_c('div',{staticClass:"svg_image_section"},[_c('div',{staticClass:"names"},[_c('a',{attrs:{"href":"https://upmsp.edu.in/","target":"_blank"}},[_c('img',{attrs:{"src":"https://iconape.com/wp-content/png_logo_vector/board-of-high-school-intermediate-uttar-pradesh-logo.png","alt":"affiliation logo"}})]),_c('p',[_vm._v("UP Board")])]),_c('div',{staticClass:"names"},[_c('a',{attrs:{"href":"https://www.bosse.ac.in/","target":"_blank"}},[_c('img',{attrs:{"src":"https://www.bosse.org.in/Images/logoBOSSE.png","alt":"affiliation logo"}})]),_c('p',[_vm._v("Board Of Open Schooling "),_c('br'),_vm._v("& Skill Education")])]),_c('div',{staticClass:"names"},[_c('a',{attrs:{"href":"https://madarsaboard.upsdc.gov.in/About.aspx","target":"_blank"}},[_c('img',{attrs:{"src":"https://madarsaboard.upsdc.gov.in/homeassets/images/HomeImg/Seal_of_Uttar_Pradesh.png","alt":"affiliation logo"}})]),_c('p',[_vm._v("Uttar Pradesh Board of Madarsh Education")])]),_c('div',{staticClass:"names"},[_c('a',{attrs:{"href":"http://www.uprtou.ac.in/","target":"_blank"}},[_c('img',{attrs:{"src":"https://uprtou.co.in/public/images/logo.png","alt":"affiliation logo"}})]),_c('p',[_vm._v("Uttar Pradesh Rajarshi Tandon Open University, Prayagraj")])]),_c('div',{staticClass:"names"},[_c('a',{attrs:{"href":"https://ddugu.ac.in/newweb/index.php","target":"_blank"}},[_c('img',{attrs:{"src":"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTN5QRw5S_stJPGA8crAv5QhPNq0Aem32Isk_6DStPYOpWwpqRWhXN1MJ_ZfWe-XQxqObw&usqp=CAU","alt":"affiliation logo"}})]),_c('p',[_vm._v("Deen Dayal Upadhyaya"),_c('br'),_vm._v(" Gorakhpur University")])])])])
}]

export { render, staticRenderFns }