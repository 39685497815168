<template>
  <div class="container">
    <div class="left-panel">
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        @click="selectTab(index)"
        :class="{
          'selected-tab': selectedTab === index,
          'unselected-tab': selectedTab !== index
        }"
        class="tab"
      >
        {{ tab.heading }}
      </div>
    </div>

    <div class="right-panel">
      <h2>{{ tabs[selectedTab].heading }}</h2>
      <p v-html="tabs[selectedTab].content"></p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedTab: 0,
      tabs: [
        { 
          heading: 'Introduction', 
          content: `S.K. Education Group is a leading educational institution dedicated to fostering academic excellence and personal growth among students. 
          Comprising multiple colleges and educational centers such as S.K. Inter College, S.K. Kanya Mahavidaya,  and NSS, 
          our group is committed to providing quality education and holistic development. 
          Our mission is to create an inclusive environment that nurtures the academic, professional, and personal growth of students 
          while preparing them to become leaders in their chosen fields.`
        },
        { 
          heading: 'Vision & Mission', 
          content: `
            <h3>Mission Statement:</h3>
            At S.K. Education Group, our mission is to provide an enriching educational experience that promotes academic, personal, and professional development. We aim to:
            <br><br>
            1. <strong>Quality Education:</strong> Offer a diverse and comprehensive curriculum that enables students to achieve academic excellence.
            <br>
            2. <strong>Skill Development:</strong> Equip students with the necessary skills to thrive in an ever-changing world, particularly in technology, science, and the arts.
            <br>
            3. <strong>Holistic Growth:</strong> Foster an environment that encourages participation in extracurricular activities such as NSS, promoting leadership, discipline, and social responsibility.
            <br>
            4. <strong>Qualified Faculty:</strong> Ensure that our teachers are highly qualified and trained, providing the best educational guidance and support.
            <br>
            5. <strong>Community Engagement:</strong> Actively engage with the local community to make a positive impact through education and outreach programs.
            <br><br>
            <h3>Vision Statement:</h3>
            Our vision is to be a leading educational institution recognized for academic excellence, innovation, and community service. We aspire to:
            <br><br>
            1. <strong>Educational Leadership:</strong> Position our group of colleges as a beacon of high-quality education that inspires and empowers future generations.
            <br>
            2. <strong>Global Competence:</strong> Prepare students to become globally competent individuals who can confidently contribute to the global community.
            <br>
            3. <strong>Innovation in Teaching:</strong> Continuously evolve our teaching methods and curriculum to incorporate the latest advancements in education, technology, and pedagogy.
            <br>
            4. <strong>Sustainable Growth:</strong> Expand our educational offerings and infrastructure to accommodate the growing needs of our students and the community while maintaining our commitment to quality.
            <br>
            5. <strong>Inclusive Environment:</strong> Cultivate an inclusive and supportive environment that respects diversity and fosters a sense of belonging among all students and staff.
          `
        },
        { 
          heading: 'Facilities', 
          content: `
            At S.K. Education Group, we pride ourselves on offering a wide array of facilities that contribute to the overall development of our students. 
            Our infrastructure and amenities are designed to provide a conducive environment for learning, physical activity, and personal growth.
            <br><br>
            <strong>1. Transportation Facilities:</strong> Understanding the importance of safe and reliable transportation, S.K. Education Group offers a well-organized bus service that caters to students from various parts of the city and surrounding areas. Our fleet of buses is equipped with safety features, and we ensure that all drivers are trained and experienced. This service is designed to make the daily commute comfortable and stress-free, allowing students to focus on their studies and extracurricular activities.
            <br><br>
            <strong>2. Sports Ground:</strong> We believe in the importance of physical fitness and sports as integral components of a well-rounded education. Our expansive sports ground provides students with ample space for various sports activities, including football, cricket, basketball, and athletics. In addition to regular physical training sessions, students are encouraged to participate in inter-college and state-level sports competitions. The ground is well-maintained and equipped with the necessary facilities to support a wide range of sports activities.
            <br><br>
            <strong>3. Physical Training Programs:</strong> Physical training is an essential part of the curriculum at S.K. Education Group. Our trained instructors provide regular physical education classes that focus on developing students' physical strength, endurance, and overall fitness. These sessions are designed to instill a sense of discipline and promote a healthy lifestyle. Additionally, we offer specialized training for students who wish to pursue careers in sports or physical education.
            <br><br>
            <strong>4. Library:</strong> Our library is a treasure trove of knowledge, offering a vast collection of books, journals, and digital resources. It serves as a hub for academic research and study, providing students with access to a wide range of subjects and disciplines. The library is equipped with comfortable seating arrangements, study rooms, and computer terminals to facilitate research and learning. Our collection is regularly updated to include the latest publications and resources, ensuring that students have access to the most current information.
            <br><br>
            <strong>5. Hi-Tech Infrastructure:</strong> Our commitment to providing a high-quality education is reflected in our investment in hi-tech infrastructure. Classrooms are equipped with smart boards and audio-visual aids to enhance the learning experience. We also provide access to online learning platforms and digital resources, allowing students to learn at their own pace. The campus is Wi-Fi enabled, ensuring that students have access to the internet for academic purposes.
            <br><br>
            <strong>6. Extracurricular Activities:</strong> S.K. Education Group places a strong emphasis on the holistic development of students, and extracurricular activities are an integral part of our educational approach. We offer a wide range of clubs and societies, including debate clubs, cultural societies, and science clubs, where students can explore their interests and talents. These activities provide opportunities for students to develop leadership skills, teamwork, and creativity.
            <br><br>
            <strong>7. Hostel Facilities:</strong> For students who come from distant locations, we offer well-furnished hostel facilities that provide a home-like environment. The hostels are equipped with modern amenities, including study rooms, recreational areas, and mess facilities that offer nutritious meals. We ensure that the hostel environment is safe and conducive to academic and personal growth.
            <br><br>
            <strong>8. Health and Wellness:</strong> The health and wellness of our students are of paramount importance. We have an on-campus health center that provides medical care and counseling services. Regular health check-ups are conducted, and we also organize wellness programs and workshops to promote mental and physical well-being among students.
          `
        },
        { 
          heading: 'Contact Us', 
          content: `
            <strong>S.K. Education Group</strong><br>
            Address: Tilauli, Sohnag, Salempur, Dist - Deoria, state- Uttar Pradesh , PIN code 274509<br>
            Phone: +91 73938 86146<br>
            Phone: +91 75100 51044<br>
            Email: info@skedugroup.in<br>
            <br>
            <strong>Contact Information:</strong><br>
            <ul>
              <li><strong>Office Hours:</strong> Monday to Saturday - 9:00 AM to 5:00 PM</li>
              <li><strong>Admissions Office:</strong> admission@skedugroup.in</li>
              <li><strong>General Inquiries:</strong> info@skedugroup.in</li>
            </ul>
            <br>
            <strong>Social Media:</strong><br>
            Follow us on our social media channels to stay updated with the latest news and events:<br>
            Facebook: <a href="https://m.facebook.com/profile.php?id=100090915075044&mibextid=LQQJ4d" target="_blank">SKEducationGroup</a><br>
            Instagram: <a href="https://www.instagram.com/s.k_education_group?igsh=dnRrbXQ0aWIxcW1u" target="_blank">SKEducationGroup</a><br>
            Youtube: <a href="https://youtube.com/@s.k_education_group?si=2v9p9D_T3FP23eCz" target="_blank">@SKEducationGroup</a><br>
            <br>
            <strong>Visit Us:</strong><br>
            We welcome you to visit our campus to learn more about our programs, facilities, and the vibrant community that makes S.K. Education Group a leading educational institution. Please contact our admissions office to schedule a campus tour or meet with our staff.
          `
        }
      ]
    };
  },
  methods: {
    selectTab(index) {
      this.selectedTab = index;
    }
  }
};
</script>

<style>
.container {
  display: flex;
  flex-direction: row;
}

.left-panel {
  width: 25%;
  background-color: #ffffff;
  border-right: 1px solid #ccc;
}

.right-panel {
  width: 75%;
  padding: 20px;
  height: 60vh; /* Set the height of the right panel */
  overflow-y: scroll; /* Enable vertical scrolling */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.right-panel::-webkit-scrollbar {
  width: 0px; /* Hide scrollbar for WebKit-based browsers */
}

.tab {
  padding: 15px;
  cursor: pointer;
}

.selected-tab {
  background-color: #ECD8A2;
  color: #fff;
}

.unselected-tab {
  background-color: #f8f8f8;
  color: #000;
}

.tab:hover {
  background-color: #ECD8A2;
  color: #fff;
}

@media screen and (max-width:500px){
  .container {
    display: flex;
    flex-direction: column;
  }
  .left-panel {
    width: 100%;
    background-color: #ffffff;
    border-right: 1px solid #ccc;
  }

  .right-panel {
    width: 100%;
    padding: 20px;
    height: 60vh; /* Set the height of the right panel */
    overflow-y: scroll; /* Enable vertical scrolling */
    scrollbar-width: none; /* Hide scrollbar for Firefox */
  }
}
</style>
