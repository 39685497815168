<template>
<div style="width:100%; disply:flex; flex-direction:row; align-items:center; justify-content:center;">
  <div class="notifications">
    <div v-for="(section, index) in sections" :key="index" class="section">
      <h2>{{ section.name }}</h2>
      <ul>
        <li v-for="(notification, idx) in section.notifications" :key="idx">
          {{ notification }}
        </li>
      </ul>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'NotificationsComponent',
  data() {
    return {
      sections: [
        {
          name: 'UP Board',
          notifications: [
            'UP Board 10th results announced.',
            'UP Board 12th compartment exams scheduled.',
            'New syllabus for UP Board 2024 released.'
          ]
        },
        {
          name: 'Deen Dayal Upadhyaya Gorakhpur University',
          notifications: [
            'DDU admission forms available online.',
            'DDU semester exams postponed.',
            'DDU introduces new courses for 2024.'
          ]
        },
        {
          name: 'Uttar Pradesh Rajarshi Tandon Open University, Prayagraj',
          notifications: [
            'UPRTOU online classes schedule released.',
            'UPRTOU assignments submission deadline extended.',
            'UPRTOU examination forms available online.'
          ]
        },
        {
          name: ' Board Of Open Schooling & Skill Education',
          notifications: [
            'BOSSE announces new vocational courses.',
            'BOSSE 12th board results are out.',
            'BOSSE exam application forms are now open.'
          ]
        }
      ]
    };
  }
};
</script>

<style scoped>
.notifications {
  max-width: 80%;
  padding: 20px;
  margin: 0px auto;
}

.section {
  padding: 10px;
  margin-bottom: 30px;
}

h2 {
  width: 100%;
  padding: 10px;
  color: white;
  background-color: #ECD8A2;
  font-size: 24px;
  margin-bottom: 10px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  color: #333;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .notifications {
    padding: 10px;
  }

  h2 {
    font-size: 20px;
  }

  li {
    font-size: 16px;
  }
}
</style>
