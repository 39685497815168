import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/students-rules',
    name: 'StudentRules',
    component: () => import('@/components/StudentRulesComponent.vue')
  },
  {
    path: '/teacher-rules',
    name: 'TeacherRules',
    component: () => import('@/components/TeachersRulesComponent.vue')
  },
  {
    path: '/visitor-rules',
    name: 'VisitorRules',
    component: () => import('@/components/VisitorRules.vue')
  },
  {
    path: '/nss',
    name: 'Nss',
    component: () => import('@/components/NSSComponent.vue')
  },
  {
    path: '/contact',
    name: 'Contact Us',
    component: () => import('@/components/ContactUsComponent.vue')
  },
  {
    path: '/result',
    name: 'Result',
    component: () => import('@/components/ResultComponent.vue')
  },
  {
    path: '/skkanyamahavidyalaya',
    name: 'College Page',
    component: () => import('@/components/CollegePage.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
